<template>
  <div class="media-zoom-carousel">
    <div class="media-zoom-carousel__container row flex media-gallery2">
      <div class="media-zoom-carousel__gallery">
        <carousel
          :per-page="1"
          :mouse-drag="true"
          :navigation-enabled="false"
          :center-mode="true"
          :resistance-coef="500"
          :min-swipe-distance="0"
          :pagination-enabled="true"
          navigation-next-label="<i class='arrow right p15 cl-main pointer' />"
          navigation-prev-label="<i class='arrow left p15 cl-main pointer' />"
          ref="zoomCarousel"
          class="media-zoom-carousel__carousel"
          :speed="carouselTransitionSpeed"
          :navigate-to="currentPage"
        >
          <slide
            v-for="(images, index) in gallery"
            :key="images.src"
          >
            <div class="media-zoom-carousel__slide"
                 :class="{'video-container h-100 flex relative': images.video}"
            >
              <product-gallery-image
                v-show="hideImageAtIndex !== index"
                :image="images"
                :alt="productName | htmlDecode"
                :is-active="index === currentPage"
              />
              <product-video
                v-if="images.video && (index === currentPage)"
                v-bind="images.video"
                :index="index"
                @video-started="onVideoStarted"
              />
            </div>
          </slide>
        </carousel>
      </div>
      <div class="visible-xs mobile-navigation absolute w-100 pl15 pr20">
        <div class="flex between-xs pb25">
          <div class="arrow-container flex middle-xs">
            <div class="mr20 arrow-l" @click="SlideCarousel('backward')">
              <span class="arrow left" />
            </div>
            <div class="arrow-r" @click="SlideCarousel('forward')">
              <span class="arrow right" />
            </div>
          </div>
        </div>
      </div>
      <div id="menu" class="menu">
        <div v-for="(images, key) in gallery" :key="images.src" class="item" @mouseover="hoverEffect(key)" @mouseleave="leaveEffect()" :data-id="key">
          <div class="item-child" :data-active="activeValue(key)">
            <img
              key="lowQualityImage"
              width="150px"
              height="180px"
              :src="images.src"
              ref="images"
              @mouseover="navigate(key)"
              :alt="productName | htmlDecode"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import ProductImage from './ProductImage'
import ProductGalleryImage from './ProductGalleryImage'
import ProductVideo from './ProductVideo'

export default {
  name: 'ProductGalleryZoomCarousel',
  props: {
    currentSlide: {
      type: Number,
      required: false,
      default: 0
    },
    gallery: {
      type: Array,
      required: true
    },
    productName: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      carouselTransitionSpeed: 300,
      currentPage: 0,
      hideImageAtIndex: null,
      currentActive: null
    }
  },
  components: {
    'Carousel': () => import(/* webpackChunkName: "vue-carousel" */ 'vue-carousel').then(Slider => Slider.Carousel),
    'Slide': () => import(/* webpackChunkName: "vue-carousel" */ 'vue-carousel').then(Slider => Slider.Slide),
    ProductImage,
    ProductGalleryImage,
    ProductVideo
  },
  mounted () {
    this.$nextTick(() => {
      disableBodyScroll(this.$refs.thumbs)
    })
    this.currentPage = this.currentSlide
    this.navigate(this.currentSlide)
    if (this.$refs.zoomCarousel) {
      let navigation = this.$refs.zoomCarousel.$children.find(c => c.$el.className === 'VueCarousel-navigation')
      let pagination = this.$refs.zoomCarousel.$children.find(c => c.$el.className === 'VueCarousel-pagination')
      if (navigation !== undefined) {
        navigation.$on('navigationclick', this.increaseCarouselTransitionSpeed)
      }
      if (pagination !== undefined) {
        pagination.$on('paginationclick', this.increaseCarouselTransitionSpeed)
      }
    }
  },
  beforeDestroy () {
    clearAllBodyScrollLocks()
  },
  methods: {
    SlideCarousel (value) {
      const carousel = this.$refs.zoomCarousel;
      const currentPage = carousel.currentPage;
      const pageCount = carousel.pageCount;
      if (value === 'backward') {
        currentPage !== 0 ? carousel.goToPage(currentPage - 1) : carousel.goToPage(pageCount - 1);
      } else {
        currentPage < pageCount - 1 ? carousel.goToPage(currentPage + 1) : carousel.goToPage(0);
      }
    },
    hoverEffect (index) {
      this.currentActive = index
    },
    leaveEffect () {
      this.currentActive = null
    },
    activeValue (index) {
      if (index === this.currentActive) {
        return 0.5
      } else if ((this.currentActive === index + 1) || (this.currentActive === index - 1)) {
        return 0.25
      } else if ((this.currentActive === index + 2) || (this.currentActive === index - 2)) {
        return 0.12
      } else {
        return 0
      }
    },
    navigate (key) {
      if (this.$refs.zoomCarousel) {
        this.$refs.zoomCarousel.goToPage(key)
      }
    },
    // navigate (index) {
    //   this.currentPage = index
    // },
    increaseCarouselTransitionSpeed () {
      this.carouselTransitionSpeed = 500
    },
    pageChange (index) {
      this.currentPage = index
      this.hideImageAtIndex = null
    },
    onVideoStarted (index) {
      this.hideImageAtIndex = index
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/base/global_vars';
@import '~theme/css/animations/transitions';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);

.mobile-navigation {
  bottom: 0;
  .arrow-container {
    min-width: 100px;
  }
  .arrow {
    border: solid $main;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 5px;
    right: 20px;
    bottom: 22px;
  }
  .left {
    transform: rotate(135deg);
  }
  .right {
    transform: rotate(-45deg);
  }
  padding-bottom: env(safe-area-inset-bottom);
}
.media-zoom-carousel {
  * {
    box-sizing: border-box;
  }

  &__container{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    overflow: hidden;
    padding: 15px;
    max-height: 100%;
    justify-content: space-evenly;
  }

  &__thumbs{
    list-style: none;
    padding-right: 20px;
    width:100%;
    max-width: 140px;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__thumb{
    margin-bottom: 20px;
    max-width: 100%;
    cursor: pointer;

    &:last-of-type {
      margin-bottom: 0;
    }

    & > *{
      opacity: .9;
      will-change: opacity;
      transition: .3s opacity $motion-main;

      &:hover{
        opacity: 1;
      }
    }
  }

  &__gallery{
    margin-top: auto;
    margin-bottom: auto;
    max-width: 75vh;
    width: 100%;
    @media (max-width: 767px) {
      margin-top: auto;
      margin-bottom: auto;
      max-width: 120vh;
      width: auto;
    }
  }

  &__slide{
    height: 100%;
    max-height: 100%;
  }
}
.thumb-video{
  padding-bottom: calc(319% / (568 / 100));
}
.video-container {
  align-items: center;
  justify-content: center;
}
#menu{
  margin-top: auto;
  display: flex;
  max-height: 150px;
  width: 100%;
  justify-content: center;
  @media (max-width: 767px) {
    display: none;
  }
  .item{
    display: flex;
    align-items: flex-end;
    img{
      width: 140px;
      height: auto;
    }
    .item-child {
      transform-origin: bottom;
      transition:  all 125ms ease-in-out;
      cursor: pointer;
      &[data-active="1"]{
        transform: scale(1);
      }
      &[data-active="0.75"]{
        transform: scale(0.88);
      }
      &[data-active="0.5"]{
        transform: scale(0.77);
      }
      &[data-active="0.25"]{
        transform: scale(0.66);
      }
      &[data-active="0.12"]{
        transform: scale(0.55);
      }
      &[data-active="0"]{
        transform: scale(0.5);
      }
    }
  }
}
</style>

<style lang="scss">
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);

.media-zoom-carousel {
  .arrow {
    border: solid $main;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 6px;
    right: 20px;
    bottom: 22px;
  }
  .left {
    transform: rotate(135deg);
  }
  .right {
    transform: rotate(-45deg);
  }
  .VueCarousel-wrapper,
  .VueCarousel-inner {
    height: 100% !important;
    @media (max-width: 767px) {
      visibility: visible!important;
    }
  }
  .VueCarousel-slide {
    height: 100%;
  }
  .VueCarousel-navigation-button {
    @media (max-width: 767px) {
      display: none;
    }
  }
}
.arrow-r {
  @media (max-width: 767px) {
      position: absolute;
      left: 75%;
      bottom: 25%;
      transform: translate(-50%, -50%);
        background: white;
        padding-left: 12px;
        padding-right: 19px;
        padding-top: 12px;
        padding-bottom: 10px;
        border-radius: 50%
      }}
      .arrow-l {
  @media (max-width: 767px) {
    position: absolute;
    left: 25%;
    bottom: 25%;
    transform: translate(-50%, -50%);
    background: white;
    padding-left: 16px;
    padding-right: 15px;
    padding-top: 12px;
    padding-bottom: 10px;
    border-radius: 50%;
}

      }
</style>

<template>
  <div class="media-zoom">
    <span class="media-zoom__close flex middle-xs pointer" @click="$emit('close')">
      <span class="hidden-xs uppercase cl-main">{{ $t('close preview') }}</span>
      <i class="icon-close icon-close-paddings h4 cl-main" @click="$emit('close')" />
    </span>
    <no-ssr>
      <product-gallery-zoom-carousel
        v-if="showProductGalleryZoomCarousel"
        :current-slide="currentSlide"
        :gallery="gallery"
        :product-name="productName"
      />
    </no-ssr>
  </div>
</template>
<script>
import NoSSR from 'vue-no-ssr'
import ProductGalleryZoomCarousel from './ProductGalleryZoomCarousel.vue'

export default {
  name: 'ProductGalleryOverlay',
  props: {
    currentSlide: {
      type: Number,
      required: false,
      default: 0
    },
    gallery: {
      type: Array,
      required: true
    },
    productName: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      showProductGalleryZoomCarousel: false
    }
  },
  components: {
    'no-ssr': NoSSR,
    ProductGalleryZoomCarousel
  },
  mounted () {
    this.$store.commit('ui/setOverlay', true)
    this.showProductGalleryZoomCarousel = true
  },
  destroyed () {
    this.$store.commit('ui/setOverlay', false)
  }
}
</script>
<style lang="scss" scoped>
@import '~theme/css/base/global_vars';
$z-index-gallery: map-get($z-index, overlay) + 1;

.media-zoom {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
  background: #fff;
  .icon-close-paddings {
    padding: 20px;
    @media (max-width: 767px) {
      padding: 0 0px 0px 0px;
      margin-bottom: env(safe-area-inset-bottom);
    }
  }

  &__close {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
    @media (max-width: 767px) {
      bottom: 4%;
      border-radius: 50%;
      top: unset;
      background-color: white;
      transform: translate(50%, 0px);
      right: 50%;
    }
  }
}
</style>
